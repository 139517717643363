import React from "react"
import './navbar.scss'

import PropTypes from "prop-types"
import { connect } from "react-redux"

class Navbar extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    const top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

    if(top == 0) this.props.hideMenu()
    if(top >= viewportHeight) this.props.showMenu()
  }
  
  render() {
    return(
      <nav className={ this.props.menu.show ? 'site__navbar active' : 'site__navbar' }>
        <ul className="navbar">
          <li className="item">
            <a className="nav__link" href="#about">About</a>
          </li>
          <li className="item">
            <a className="nav__link" href="#portfolio">Portfolio</a>
          </li>
          <li className="item">
            <a className="nav__link" href="#contact">Contact</a>
          </li>
        </ul>
      </nav>
    )
  }
}

Navbar.propTypes = {
  showMenu: PropTypes.bool,
  hideMenu: PropTypes.func.isRequired,
  showMenu: PropTypes.func.isRequired,
}

const mapStateToProps = ( store ) => {
  return store
}

const mapDispatchToProps = dispatch => {
  return { 
    hideMenu: () => dispatch({ type: 'MENU_HIDE' }),
    showMenu: () => dispatch({ type: 'MENU_SHOW' })
  }
}

const ConnecNavbar = connect(mapStateToProps, mapDispatchToProps)(Navbar)

export default ConnecNavbar
