import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import { Portfolio } from "../components/portfolio/index"
import Profile from "../components/profile/"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Profile />
    <Portfolio />
  </Layout>
)

export default IndexPage
