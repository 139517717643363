import { Arrows } from './pagination/index'
import Carousel from './carousel/index'
import Metadata from './metadata/index'
import Portfolio from './app/index'
import Slide from './slide/index'
import projects from './data';

const isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i)
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i)
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i)
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i)
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i)
    },
    any: function () {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      )
    },
}


export { Arrows, Carousel, Metadata, Portfolio, Slide, isMobile, projects }