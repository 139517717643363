import React from "react"
import "./profile.scss"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

const Profile = () => (
  <section className="profile">
    <a className="section__anchor section__anchor--profile" name="about"></a>
    <div className="container">
      <div className="about">
        <div className="container__image">
          <div className="profile__image">
            <Image />
          </div>
        </div>
        <div className="profile__description">
          <h2 className="title">About</h2>
          <h3 className="title title--alt">
            May I help you to build your next project?
          </h3>
          <p className="text">Hello I’m Wilhelm <span role="img">👋</span>,</p>
          <p className="text">A Frontend developer making websites and applications  since 15 years, large part of this time I've worked in Advertising companies with great teams to bring alive cool projects, that have to be delivered with quality and quick.</p>
          <div className="box">
            <h5 className="title">Frontend Web Development</h5>
            <p className="text">I will help you in implementing the beautiful designs into interactive, responsive, performant, accessible and progressively-enhanced web pages.</p>
          </div>
          <ul>
            <li>Implement .PSD/.Sketch/.XD files into interactive web pages</li>
            <li>Responsive Web Development</li>
            <li>Fixing CSS issues for a current website</li>
            <li>Web Performance</li>
          </ul>
          <p className="text">Please visit my <a href="https://www.linkedin.com/in/wsiso/" target="_blank" title="Wilhelm Siso - Frontend Developer - LinkedIn" rel="noopener">LinkedIn Profile</a> to know more about my expertice and skills.</p>
          <p className="text">If you are looking for a developer to build your next project on the Web I'm that guy, <a href="#contact">contact me.</a></p>
        </div>
      </div>
    </div>
  </section>
)

export default Profile
