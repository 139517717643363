import React from 'react'
import { Arrows, Slide, isMobile, projects } from './../index'
import './index.scss'

class Carousel extends React.Component {

  constructor(props) {
    super(props)
    this.slide = React.createRef()

    this.state = {
      active: 0,
      autoplay: false,
      isMobile: isMobile.any(),
      loaded: false,
      max: 0,
      slides: [],
      slideWidthUpdate: false,
      updateMeta: props.updateMeta,
      wrapperStyle: {}
    }
  }

  componentDidMount = () => {
    const slides = []
    const transform = `translateX(0vw)`
    let width = null

    projects.forEach((project) => {
      return slides.push({
        eachSlide: this.state.isMobile 
        ? project.images.mobile
        : project.images.desktop
      })
    })

    width = `${slides.length * 100}vw`

    this.setState({
      loaded: true,
      max: projects.length,
      slides,
      wrapperStyle: {
        transform: transform,
        width: width
      }
    })

    if (this.state.autoplay)
      this.interval = setInterval(() => this.intervalBetweenSlides(), 10000)
  }

  componentWillUnmount = () => {
    if (this.state.autoplay)
      clearInterval(this.interval)
  }

  intervalBetweenSlides = () => {
    if (this.state.active === this.state.max - 1) {
      this.setState({ active: 0 })

    } else {
      const newValue = this.state.active + 1
      this.setState({ active: newValue })
    }

    this.setState({
      active: this.state.active
    })
  }

  nextOne = () => {
    if (this.state.active < this.state.max - 1) {
      this.setState({
        active: this.state.active + 1
      }, () => {
        this.state.updateMeta(this.state.active)
        this.setSliderStyles()
      })
    }
  }

  prevOne = () => {
    if (this.state.active > 0) {
      this.setState({
        active: this.state.active - 1
      }, () => {
        this.state.updateMeta(this.state.active)
        this.setSliderStyles()
      })
    }
  }

  setSliderStyles = () => {
    let result = '0'
    let transition
    let transform
    let width

    if (this.slide.current != null) {
      const value = this.slide.current.childNodes.item(0).clientWidth
      const w = window,
        d = document,
        e = d.documentElement,
        g = value,
        x = w.innerWidth || e.clientWidth || g.clientWidth

      result = (100 * value) / x

      if (!this.state.slideWidthUpdate) {
        this.setState({
          slideWidthUpdate: true
        })
      }
    }

    transition = this.state.active * - result
    transform = `translateX(${transition}vw)`
    width = `${(this.state.slides.length * 100)}vw`

    this.setState({
      wrapperStyle: {
        transform,
        width
      }
    })
  }

  renderSlides = () => this.state.slides.map((item, index) => {
    return <Slide key={index} id={index} project={item} />
  })

  render() {
    if (!this.state.loaded) return (<div>Loading...</div>)

    return (
      <div className="carousel">
        <div className="slider">
          <div
            className="wrapper"
            style={this.state.wrapperStyle} ref={(this.slide)}>
            {this.renderSlides()}
          </div>
          <Arrows activeItem={this.state.active} totalItems={(this.state.slides.length - 1)} handleNext={this.nextOne} handlePrev={this.prevOne} />
        </div>
        {
          /*
          <Bullets projects={this.state.slides} updateMeta={this.state.updateMeta} updateSlider={this.setSliderStyles} />
          */
        }
      </div>
    )
  }
}

export default Carousel