import PropTypes from "prop-types"
import React from "react"
import "./header.scss"

const Header = ({ siteTitle }) => (
  <header className="site__header">
    <a className="section__anchor" name="home"></a>
    <h2 className="title big">
      Make it Better.<br />
      Faster.<br />
      Accesible.
    </h2>
    <h1 className="title small">Wilhelm Siso</h1>
    <h3 className="title small">Frontend Developer</h3>
    <div className="square">
      <div className="item"></div>
      <div className="item"></div>
      <div className="item"></div>
      <div className="item"></div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
