import cartonerosDesktop from './img/cartoneros.jpg'
import cartonerosMobile from './img/cartoneros-mobile.jpg'
import goicoecheaDesktop from './img/cremas-goicoechea.jpg'
import goicoecheaMobile from './img/cremas-goicoechea-mobile.jpg'
import hyperfocusROIDesktop from './img/hyperfocusroi.jpg'
import hyperfocusROIMobile from './img/hyperfocusroi.jpg'
import protonDesktop from './img/proton.jpg'
import protonMobile from './img/proton-mobile.jpg'
import urbanaDesktop from './img/urbana-las-lomas.jpg'
import urbanaMobile from './img/urbana-las-lomas-mobile.jpg'

const projects = [
  {
    name: 'Hyperfocus ROI',
    description: "HyperFocus ROI is a media and business growth strategy consulting agency.",
    url: 'https://hyperfocusroi.com/',
    developed: {
      kind: 'fulltime job',
      company: {
        name: 'Zetenta',
        url: '',
      }
    },
    images: {
      desktop: hyperfocusROIDesktop,
      mobile: hyperfocusROIMobile,
    },
  },
  {
    name: 'Urbana Las Lomas',
    description: "Urbana Las Lomas is a residential complex in San Isidro with the comforts and privacy of living in a house.",
    url: 'https://www.urbanalaslomas.com/departamentos/',
    developed: {
      kind: 'fulltime job',
      company: {
        name: 'Zetenta',
        url: '',
      }
    },
    images: {
      desktop: urbanaDesktop,
      mobile: urbanaMobile,
    },
  },
  {
    name: 'Cremas Goicoechea',
    description: "The Goiecoecha line is the best selling specialist leg cream. Its formulas provide the perfect combination of care and beauty with ingredients of natural origin to moisturize, refresh and help relieve the feeling of heaviness and tiredness.",
    url: 'https://cremasgoicoechea.com.ar/',
    developed: {
      kind: 'fulltime job',
      company: {
        name: 'Social Snack',
        url: '',
      }
    },
    images: {
      desktop: goicoecheaDesktop,
      mobile: goicoecheaMobile,
    },
  },
  {
    name: 'Proton Cine',
    description: "Protón Cine founded in 2017 in Mendoza, Argentina, based in Buenos Aires. It is a producer that bets movies with a microscopic and radical look. It was created in the spirit of promoting energetic authors and accompany them integrally in the process of concretizing their ambitions, with the aim of offering them a “positive force” that drives their projects.",
    url: 'http://protoncine.com/',
    developed: {
      kind: 'fulltime job',
      company: {}
    },
    images: {
      desktop: protonDesktop,
      mobile: protonMobile,
    },
  },
  {
    name: 'Cartoneros y sus chicos',
    description: "Under the leadership of Renata Jacobs, “Cartoneros y sus Chicos” has the set the goal of enabling the Cartoneros and their children to live better lives and look forward to a brighter future.",
    url: 'http://cartoneros.org/',
    developed: {
      kind: 'fulltime job',
      company: {}
    },
    images: {
      desktop: cartonerosDesktop,
      mobile: cartonerosMobile,
    },
  },
]

export default projects