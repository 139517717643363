import React from 'react'
import './index.scss'

const Header = (props) => (<h3 className="name">{props.name}</h3>)

const Text = (props) => {
  const {content, label} = props

  return (
    <p className="text">{label} {content}</p>
  )
}

const URL = (props) => {
  const {link, title} = props

  return (
    <p className="text">
      <a className="link" href={link} target="_blank" title={title} rel="noopener noreferrer">Go to site</a>
    </p>
  )
}

const showText = (content, developed) => {
  const {company} = developed
  return (
    <div>
      <Text content={content} />
      <Text 
        content={(company.name) ? company.name : null}
        label={(company.name) ? 'Developed at:' : 'Freelance'} />
    </div>
  )
}

const Metadata = (props) => {
  const {content, developed, hide, link, name} = props

  return (
    <div className={ hide ? 'meta hide' : 'meta' }>
      <Header name={name} />
      {showText(content, developed)}
      <URL link={link} title={name} />
    </div>
  )
}

export default Metadata
