import React from "react"
import './footer.scss';

export default class Footer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      animateButton: false
    };
  }

  componentDidMount = () => {
    this.interval = setInterval(() => this.intervalBetweenSlides(), 500);
  }

  componentWillUnmount = () => {

    clearInterval(this.interval);
  }

  intervalBetweenSlides = () => {
    this.setState({
      bottom: false
    });

    if (((window.innerHeight + window.scrollY) + 5) >= document.body.offsetHeight) {
      // you're at the bottom of the page
      this.setState({
        bottom: true
      });

    }
    this.setState({ animateButton: this.state.bottom });
  }

  render() {
    return (
      <footer className="site__footer">
        <a className="section__anchor" name="contact"></a>
        <div className="container container--footer">
          <div className="contact__me">
            <h3 className="title">Let's work together</h3>
            <a className={this.state.animateButton ? 'btn btn--contact animate' : 'btn btn--contact'} href="mailto:wilhelm@wsiso.com" title="Contact me">Contact Me</a>
          </div>
          <nav className="social__links">
            <ul className="items">
              <li className="item item--linkedin">
                <a className="link" href="https://www.linkedin.com/in/wsiso/" target="_blank" title="Wilhelm Siso Frontend Developer Linkedin" rel="noopener">Linkedin</a>
              </li>
              <li className="item item--github">
                <a className="link" href="https://github.com/wilcho-vnz/" target="_blank" title="Wilhelm Siso Frontend Developer Github" rel="noopener">Github</a>
              </li>
              <li className="item item--twitter">
                <a className="link" href="https://twitter.com/wilcho_" target="_blank" title="Wilhelm Siso Frontend Developer Twitter" rel="noopener">Twitter</a>
              </li>
              <li className="item item--wordpress">
                <a className="link" href="https://blog.wsiso.com/" target="_blank" title="Wilhelm Siso Frontend Developer Blog" rel="noopener">Blog</a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="copyright">
          <p className="text">Wilhelm Siso, Frontend Developer {new Date().getFullYear()} Design by Wilhelm Siso, Built with {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a></p>
        </div>
      </footer>
    );
  }
}