import React from 'react'
import './index.scss'

const Slide = (props) => {
  const {id, project} = props
  const bg = `url(${project.eachSlide})`

  return (
    <div
      className='each-slide'
      key={id}
      style={{backgroundImage: bg}}>
    </div>
  )
}

export default Slide