import React from 'react'
import './arrows.scss'

const IconNext = () => {
    return (
        <svg fill="#ffffff" height="50" viewBox="0 0 24 24" width="50">
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    )
}

const IconPrev = () => {
    return (
        <svg fill="#FFFFFF" width="50" height="50" viewBox="0 0 24 24">
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    )
}

const Button = (props) => {
    const { label, show, handleClick, icon } = props
    return (
        <button 
            aria-label={ label }
            type="button"
            className={ show ? `${icon}` : `${icon} hide` }
            onClick={ () => handleClick() } >
            <span aria-hidden="true">{ label }</span>
            { props.children }
        </button>
    )
}

class Arrows extends React.Component {
    state = {
        nextSlide: null,
        prevSlide: null,
    }

    constructor(props) {
        super(props)
        this.state = {
            nextSlide: props.handleNext,
            prevSlide: props.handlePrev
        }
    }

    render() {
        return (
            <div>
                <Button label='Previous' show={ this.props.activeItem === 0 ? false : true } handleClick={ this.state.prevSlide } icon='arrows prev'>
                    <IconPrev />
                </Button>
                <Button label='Next' show={ this.props.activeItem < this.props.totalItems } handleClick={ this.state.nextSlide } icon='arrows next'>
                    <IconNext />
                </Button>
            </div>
          )
    }
}

export default Arrows