import React from "react"
import { Carousel, Metadata, projects } from './../index'
import './index.scss'

class Portfolio extends React.Component {
  state = {
    animateMeta: false,
    current: null,
    loaded: false,
    projects: [],
  }

  componentDidMount() {
    const meta = []
    let content = null, name = null, link = null, developed = null

    projects.forEach((project, index) => {
      if (index === 0) {
        content = project.description
        name = project.name
        link = project.url
        developed = project.developed
      }

      meta.push({
        description: project.description,
        name: project.name,
        url: project.url,
        developed: project.developed
      })
    })

    this.setState({
      loaded: true,
      current: {
        content,
        name,
        link,
        developed
      },
      meta
    })
  }

  updateMeta = (index) => {
    const { description, name, url, developed } = this.state.meta[index]

    const data = {
      content: description,
      name: name,
      link: url,
      developed: developed
    }

    this.hideMeta().then(
      this.showMeta(data)
    )
  }

  hideMeta = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        animateMeta: true
      }, () => setTimeout(() => resolve(), 4000))
    })
  }

  showMeta = (data) => {
    setTimeout(() => {
      this.setState({
        animateMeta: false,
        current: data
      })
    }, 500)
  }

  render() {
    if (!this.state.loaded) return (<div></div>)

    const { content, developed, link, name } = this.state.current

    return (
      <section className="portfolio">
        <a className="section__anchor" name="portfolio"></a>
        <div className="portfolio__content">
          <div className="content">
            <h2 className="title">Portfolio</h2>
            <Metadata
              content={content}
              name={name}
              link={link}
              developed={developed}
              hide={this.state.animateMeta} />
          </div>
          <div className="gallery">
            <Carousel projects={projects} updateMeta={this.updateMeta} />
          </div>
        </div>
      </section>
    )
  }
}

export default Portfolio
